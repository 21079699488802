import {defineStore} from "pinia"
import type {DirectoryData} from '~/types/DirectoryData'
import type {MediaCardData} from '~/types/media/MediaCardData'
import {useUserStore} from "~/stores/user"
import {
    DELETE_CAPTURE_REQUESTED,
    DELETE_FAVORITE_REQUESTED
} from "~/types/bus-event-names"
import type {FavoriteData} from '~/types/FavoriteData'
import type {CapturePageData} from '~/types/media/CapturePageData'
import type {MediaPageData} from '~/types/media/MediaPageData'

export const useDirectoriesStore = defineStore('directories', () => {

    const { objectToFormData } = useFormData()

    const directories = computed<DirectoryData[]>(() => {
        return useUserStore().userData.directories || []
    })
    const captures = computed<CapturePageData[]>(() => {
        return useUserStore().userData.captures || []
    })

    const emitter = useNuxtApp().$mitt
    emitter.on(DELETE_FAVORITE_REQUESTED, async (e: any) => {
        await removeFavoriteFromDirectory(e.directory.value, e.favorite.value)
    })
    emitter.on(DELETE_CAPTURE_REQUESTED, async (e: any) => {
        await deleteCapture(e.capture.value, e.context.value)
    })

    async function createDirectory(name: string, mediaData: MediaCardData|null = null, instructions: string|null = null, isOpenToCaptures: boolean|null = null) {
        let position = directories.value.length
        let body:{[key: string]: any} = {name, position}

        if (mediaData) {
            body.fromMediaId = mediaData.id
            body.fromMediaType = mediaData.mediaType
        }
        if (instructions) {
            body.instructions = instructions
        }
        if (isOpenToCaptures) {
            body.isOpenToCaptures = isOpenToCaptures
        }
        const {data, error} = await useBaseApiFetch<DirectoryData>('/favorite-directory/create', {
            method: 'post',
            body: objectToFormData(body)
        })

        let fetchError = null

        if (error.value || !data.value) {
            fetchError = useNuxtApp().$i18n.t('favorite_directory.cannot_create_directory')
        } else {
            directories.value.push(data.value)
        }

        return {'error': fetchError, 'data': data.value}
    }

    async function renameDirectory(directory: DirectoryData, newName: string) {
        const name = newName
        const code = directory.code
        const {error} = await useBaseApiFetch('/favorite-directory/update/name', {method: 'post', body: objectToFormData({name, code})})
        let fetchError = null

        if (error.value) {
            fetchError = useNuxtApp().$i18n.t('favorite_directory.cannot_rename_directory')
        }

        return fetchError
    }

    async function deleteDirectory(directory: DirectoryData) {
        const directoryCode = directory.code
        directories.value.splice(directory.position, 1)
        directories.value.forEach((directory, index) => {
            directory.position = index
        })

        const fd = codesToFormData()
        fd.append('deletedDirectoryCode', directoryCode)
        await useBaseApiFetch('/favorite-directory/delete', {method: 'post', body: fd})
        await navigateTo(useNuxtApp().$localePath('me-collections'))
    }

    async function savePositions() {
        await useBaseApiFetch('/favorite-directory/update-positions', {method: 'post', body: codesToFormData()})
    }

    function codesToFormData() {
        const codes = directories.value.map(directory => directory.code)
        const fd = new FormData()
        fd.append('codes', JSON.stringify(codes))
        return fd
    }

    function isMediaInDirectory(directory: DirectoryData, data: MediaCardData|MediaPageData) {
        return directory.favorites.filter(favorite => favorite[getFavoriteMediaType(data)]?.id === data.id).length > 0
    }

    function isStudentCaptureInDirectory(directory: DirectoryData, data: MediaCardData) {
        return directory.studentCaptures.find(studentCapture => studentCapture.id === data.id) !== undefined;
    }

    function isMediaFavorite(media: MediaCardData|MediaPageData) {
        return directories.value.filter(directory => isMediaInDirectory(directory, media)).length > 0
    }

    function getFavoriteMediaType(data: MediaCardData|MediaPageData): 'capture'|'media' {
        return data.mediaType === 'capture' ? 'capture' : 'media'
    }

    async function updateMediaPresenceInDirectory(directory: DirectoryData, cardData: MediaCardData) {
        let type = getFavoriteMediaType(cardData)

        if (isMediaInDirectory(directory, cardData)) {
            const favorite = directory.favorites.find((favorite) => favorite[type]?.id === cardData.id)
            if (favorite) {
                await removeFavoriteFromDirectory(directory, favorite)
            }
        } else {
            await addFavoriteToDirectory(directory, cardData)
        }
    }

    async function addFavoriteToDirectory(directory: DirectoryData, media: MediaCardData) {
        const fd = new FormData()
        fd.append('id', media.id.toString())
        fd.append('position', directory.favorites.length.toString())
        fd.append('code', directory.code)
        fd.append('mediaType', media.mediaType)
        const {data, error} = await useApiFetch<FavoriteData>('/favorite/create', {method: 'post', body: fd})

        if (!error.value && data.value) {
            directory.favorites.push(data.value)
            showSuccessToast(useNuxtApp().$i18n.t('favorite.added_confirmation'))
        }
    }

    async function removeFavoriteFromDirectory(directory: DirectoryData, favorite: FavoriteData) {
        const fd = new FormData()

        fd.append('id', favorite.id.toString())
        fd.append('code', directory.code)

        const {error} =  await useApiFetch('/favorite/delete', {method: 'post', body: fd})
        if (!error.value) {
            directory.favorites.splice(directory.favorites.indexOf(favorite), 1)
        }
    }

    async function updateInstructions(directory: DirectoryData) {
        let code = directory.code
        let instructions = directory.instructions
        await useBaseApiFetch('/favorite-directory/update/instructions', {method: 'post', body: objectToFormData({code, instructions})})
    }

    async function deleteCapture(capture: MediaCardData, context: string) {
        const fd = new FormData()
        fd.append('id', capture.id.toString())
        const {error} = await useApiFetch('/capture/delete', {method: 'post', body: fd})

        if (!error.value) {

            const captureData: CapturePageData|undefined = captures.value.find(c => c.id === capture.id)

            if (captureData) {
                captures.value.splice(captures.value.indexOf(captureData), 1)

                // Remove capture from directories of the current user
                directories.value.forEach(directory => {
                    if (isMediaInDirectory(directory, capture)) {
                        const favorite = directory.favorites.find((favorite) => favorite.capture?.id === capture.id)
                        if (favorite) {
                            directory.favorites.splice(directory.favorites.indexOf(favorite), 1)
                        }
                    }
                })
            }
            else if (context === "student-capture") {
                // Remove student capture from directories of the current user
                directories.value.forEach(directory => {
                    if (isStudentCaptureInDirectory(directory, capture)) {
                        const studentCapture = directory.studentCaptures.find((studentCapture) => studentCapture?.id === capture.id)
                        if (studentCapture) {
                            directory.studentCaptures.splice(directory.studentCaptures.indexOf(studentCapture), 1)
                        }
                    }
                })
            }

        }
    }

    async function updateIsOpenToCaptures(directory: DirectoryData) {
        let code = directory.code
        let isOpenToCaptures = directory.isOpenToCaptures
        await useBaseApiFetch('/favorite-directory/update/is-open-to-captures', {method: 'post', body: objectToFormData({code, isOpenToCaptures})})
    }

    async function addBookmarkToDirectory(directory: DirectoryData, bookmarkProps: {url: string, title: string}) {
        const { error, data } = await useApiFetch<FavoriteData>('/favorite/create-bookmark', {
            method: 'post',
            body: objectToFormData({
                code: directory.code,
                position: directory.favorites.length,
                ...bookmarkProps
            })
        })

        if (!error.value && data.value) {
            directory.favorites.push(data.value)
            showSuccessToast(useNuxtApp().$i18n.t('favorite.added_confirmation'))
        }
    }

    return {
        directories,
        createDirectory,
        deleteDirectory,
        savePositions,
        renameDirectory,

        isMediaInDirectory,
        isMediaFavorite,

        updateMediaPresenceInDirectory,
        updateInstructions,
        updateIsOpenToCaptures,

        addBookmarkToDirectory,
    }
})
