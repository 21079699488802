function canToast() {
  return useNuxtApp().$showToast !== undefined
}

export const showSuccessToast = (text: string) => {
  if (!canToast()) {
    return
  }

  useNuxtApp().$showSuccessToast(text)
}

export const showWarningToast = (text: string) => {
  if (!canToast()) {
    return
  }

  useNuxtApp().$showWarningToast(text)
}

export const showErrorToast = (text: string) => {
  if (!canToast()) {
    return
  }

  useNuxtApp().$showErrorToast(text)
}